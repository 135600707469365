// import videoOne from './video/videoOne.mp4'
import videoTwo from './video/videoTwo.mp4'
// import videoThree from './video/videoThree.mp4'
// import videoFour from './video/a4csr.mp4'
const medijaList = [
    {
    id: 5,
    video: "https://www.youtube.com/embed/sFiVLdHrqT0?si=NjPeof-Abi5XlHiD",
    title: `Mr Jovana Bošković, TV Gradska, najava predavanja “Četvorodnevna radna nedelja - uticaj na produktivnost i zaposlene”.`,
    date: '12.12.2023.'
    },
    {
    id: 6,
    video: "https://www.youtube.com/embed/zpPeR-o9PCo?si=Ph0UtiX_fogObzoB",
    title: `Mr Jovana Bošković, TV 7, najava predavanja “Četvorodnevna radna nedelja - uticaj na produktivnost i zaposlene”.`,
    date: '12.12.2023.'
    },
    {
        id: 4,
        video: "https://www.youtube.com/embed/RuXaZ_rG7zI?si=_YBW-W7_yr46AELv",
        title: `Mr Vanja Janković, TV E, aktuelne radne okolnosti, te da li moguće poslovati društveno odgovorno.`,
        date: '06.11.2023.'
        },
    // {
    //     id: 4,
    //     video: videoOne,
    //     title: `Mr Vanja Janković, TV E, aktuelne radne okolnosti, te da li moguće poslovati društveno odgovorno.`,
    //     date: '06.11.2023.'
    // },
    {
        id: 0,
        video: "https://www.youtube.com/embed/QIeP6yRtli8?si=_zNbH0uYKxJTHwMh",
        title: `Asocijacija za Društveno Odgovorno Poslovanje Showreel x Vanja Janković.`,
        date: '01.11.2023.'
        },
    {
    id: 8,
    video: videoTwo,
    title: `Mr Vanja Janković, TV E, pojam društveno odgovornog poslovanja (DOP) i najava radionice.`,
    date: '25.10.2023.'
    },
    {
    id: 3,
    video: "https://www.youtube.com/embed/FKfmZ1PO0M0?si=xQoAQyln7bELwKm9",
    title: `Mr Vanja Janković, TV Gradska, pojam društveno odgovornog poslovanja (DOP) i najava radionice.`,
    date: '25.10.2023.'
    },
    {
        id: 1,
        video: "https://www.youtube.com/embed/Mn2zzIQo6zk?si=91Wyaz7VPLmzer8a",
        title: `Mr Vanja Janković, TV Gradska, pojam društveno odgovornog poslovanja (DOP) i najava radionice.`,
        date: '14.09.2023.'
        }
]

export default medijaList;