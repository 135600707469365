import React from 'react';
import './Publikacije.css';

function Publikacije() {
  return (
    <div className="publikacije-container">
      
    </div>
  );
}

export default Publikacije;
