import image01 from './image/image01.png';
import image02 from './image/image02.png';
import image03 from './image/image03.jpeg';
import image04 from './image/image04.jpeg';
import image05 from './image/image05.jpg';
import image06 from './image/image06.png';
import image07 from './image/image07.jpeg';
import image08 from './image/image08.png';
import image09 from './image/image09.png';


const partneriList = [
    {
        id: 1,
        image: image06,
        link: "https://cinidobro.me/"
    },
    {
        id: 2,
        image: image02,
        link: "https://lafrenchtech.me/"
    },
    {
        id: 3,
        image: image09,
        link: "https://podgorica.me/gradski-saobracaj/"
    },
    {
        id: 4,
        image: image04,
        link: "https://urbanoctober.unhabitat.org/"
    },
    {
        id: 5,
        image: image05,
        link: "https://www.instagram.com/emba_montenegro/"
    },
    {
        id: 6,
        image: image01,
        link: "https://www.facebook.com/mteldigitalnafabrika/?locale=sr_RS"
    },
    {
        id: 7,
        image: image07,
        link: "https://www.uki-montenegro.com/"
    },
    {
        id: 8,
        image: image08,
        link: "https://www.kamenovo.me/"
    },
    {
        id: 9,
        image: image03,
        link: "https://mnest.me/"
    }
]

export default partneriList;