import './App.css';
import Navbar from "./Components/Navbar/Navbar";
import Footer from './Components/Footer/Footer';


function App() {
  return (
    <div>
      <Navbar/>
      <Footer/>
    </div>
  )

}
export default App;