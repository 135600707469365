import image01 from "./image/IMG_3195.JPG"
import image02 from "./image/IMG_3254.JPG"
import image03 from "./image/IMG_3206.JPG"
import image04 from "./image/IMG_3204.JPG"
import image05 from "./image/IMG_3202.JPG"
import image06 from "./image/IMG_3205.JPG"
import image07 from "./image/IMG_3194.JPG"
import image08 from "./image/IMG_3203.JPG"
import image09 from "./image/IMG_3201.JPG"
import image10 from "./image/IMG_3199.JPG"
import image11 from "./image/IMG_3198.JPG"

const projektiList = [
    {
        id: 5,
        title: `Edukativna radionica u saradnji sa Unijom mladih preduzetnika Crne Gore i
        Mtel digitalnom fabrikom “Društveno odgovorno poslovanje (DOP) mladih
        preduzetnika - benefiti i izazovi”`,
        img: image05,
        description: `NVO “Asocijacija za društveno odgovorno
        poslovanje” i Unija mladih preduzetnika Crne Gore u
        saradnji sa Mtel digitalnom fabrikom održala je 25.
        oktobra 2023. godine besplatnu edukativnu radionici
        pod nazivom “Društveno odgovorno poslovanje
        (DOP) mladih preduzetnika - benefiti i izazovi”, u
        prostorijama Mtel Digitalne Fabrike.
        Preduzetnici su imali priliku da se upoznaju sa
        osnovnim principama društveno odgovornog
        poslovanja, primjerima projekata i kompanija koje su
        uspješno implementirale DOP, alatima i strategijama
        za implementaciju DOP-a u sopstvenim preduzetničkim poduhvatima, kao i analizu izazova i
        prednosti koji se javljaju u ovom kontekstu. Predavač je bila mr Vanja Janković, osnivačica
        A4CSR.
        Takođe, ponosni smo što je našu radionicu o društveno
        odgovornom poslovanju za mlade preduzetnike Urban
        October Ujedinjenih nacija (UN) označio kao jedan od
        događaja koji promovišu bolju urbanu budućnost. Ova
        radionica je bila jedina u sjevernom dijelu Zapadnog
        Balkana koja je bila pokrivena na platformi i počastvovani
        smo ovim priznanjem. Našu radionicu i druge događaje
        možete pogledati na web stranici Urban October:
        https://urbanoctober.unhabitat.org/`,
        city: "Podgorica",
        date: "10.2023",
        link: "https://www.instagram.com/p/Cy1WW-1LUkr/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        showMore: false
    },
    {
        id: 1,
        title: `Projekat sa International school of Podgorica -
        UNITED KIDS INTERNATIONAL MONTENEGRO`,
        img: image01,
        description: `Naše članice mr Vanja Janković i mr Katya Letunovsky
        imale su priliku da 29. Novembra 2023. godine u
        International School of Podgorica - United Kids International
        Montenegro -u, zahvaljujući dobroj saradnji sa profesoricom
        Ninom Hajisabbagh, ocijene prezentacije učenika te škole.
        Studenti su predstavili svoje nevjerovatne projekte iz oblasti
        društveno odgovornog poslovanja. Vanja i Katya su pomogle
        profesorici da se izaberu najbolje prezentacije i rješenja uz
        davanja konkretnih primjera i sugestija.
        Jedan istaknuti projekat se bavio aplikacijom za odabir i recenzije restorana, s ciljem
        podizanja reputacije i podrške lokalnim preduzećima. Drugi projekat je bio fokusiran na
        ekološku održivost u restoranskoj industriji, naglašavajući minimalno rasipanje obnovljive
        energije. Naglašeno je kako ekološki prihvatljivi postupci ne samo da koriste društvu, već i
        povećavaju prihode kompanije. Predložen je i projekat za jačanje svijesti o uticaju pušenja na
        zdravlje i promovisanje zdravijeg načina života.
        U nastavku 6. decembra 2023. godine mr Vanja Janković,
        imala je ponovo priliku da ocijeni prezentacije učenika o
        principima društveno odgovornog poslovanja uzimajući
        studije slučaja sa svog časa pečenja, studentske kuhinje,
        transporta, časa umjetnosti i strategije 3R. Nakon
        prezentacija mr Janković sprovela interaktivni kviz o
        društveno odgovornom poslovanju`,
        city: "Podgorica",
        date: "12.2023",
        link: "https://www.instagram.com/p/C0gfVM9Icht/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==", 
        showMore: false
    },
    {
        id: 2,
        title: `Predavanje u saradnji sa Društvom za preduzetništvo, menadžment i biznis
        Ekonomskog fakulteta UCG EMBA`,
        img: image02,
        description: `A4CSR i Društvo za preduzetništvo, menadžment i biznis Ekonomskog fakulteta UCG
        EMBA organizovali su besplatno predavanje na temu “Četvorodnevna radna nedelja - uticaj
        na produktivnost i zaposlene” Janka Bulatovića, Chevening Awards (FCDO) aluminste koji
        je svoj MBA završio na University of Westminster. Predavanje je održano na Ekonomskom
        fakultetu, Sala 104, 13. decembra 2023. godine.
        Predavanje o hronološkoj modifikaciji
        radne nedelje, uticaju ukupnog broja radnih
        sati na produktivnost zaposlenih, kao i
        primjerima dobrih i primjenljivih praksi,
        privuklo je veliku pažnju prisutnih
        studenata tokom trajanja predavanja.
        Posebno nas raduje što je uspostavljena
        aktivna interakcija sa studentima, koji su
        kroz svoje komentare i postavljena pitanja
        pokazali duboko interesovanje za obrađenu
        temu. Ova interakcija nije samo pokazatelj
        angažovanosti studenata, već i potvrda
        važnosti teme koja se obrađivala. Učestvovanje studenata kroz postavljena pitanja dodatno je
        obogatilo diskusiju i omogućilo razmjenu različitih perspektiva i ideja. To je jasan znak da su
        tema produktivnosti i organizacije radnog vremena relevantne i inspirativne za mlade ljude,
        te da postoji želja za stvaranjem boljeg radnog okruženja i efikasnijim pristupima poslu.
        Ovakav nivo interesovanja predstavlja motivaciju za daljnje istraživanje i angažovanje u
        ovim važnim pitanjima koja utiču na radno okruženje i produktivnost zaposlenih`,
        city: "Podgorica",
        date: "12.2023",
        link: "https://www.instagram.com/p/Cyx7uZbonUD/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        showMore: false
    },
    {
        id: 4,
        title: `Edukativna radionica u saradnji sa kolektivom “Kamenovo beach” o društveno
        odgovornom poslovanju`,
        img: image04,
        description: `U sklopu saradnje sa kolektivom Kamenovo Beach,
        predstavnica Asocijacije za društveno odgovorno poslovanje,
        mr Vanja Janković, održala je 23. Septembra 2023. godine
        inspirativnu radionicu za zaposlene. Tokom ove interaktivne
        sesije, zajedno smo razgovarali o aktuelnoj sezoni,
        identifikovali oblasti u kojima se mogu unaprijediti poslovne
        prakse, te istražili načine primjene praksi društveno
        odgovornog poslovanja (DOP). Važno je istaći da restorani i plažni barovi koji aktivno sprovode korporativnu društvenu
        odgovornost ostvaruju bolje rezultate u upravljanju svojim zaposlenima. Implementacija
        DOP praksi u restorane ne samo da doprinosi privlačenju i zadržavanju gostiju, već i
        motivaciji i angažmanu zaposlenih. Posjedovanje pozitivnog imidža i odgovorne reputacije
        koju podržava vaša organizacija predstavlja ključnu prednost u konkurentnom poslovnom
        okruženju.
        Kroz ovu radionicu, težimo unapređenju svijesti i razumijevanja o važnosti društveno
        odgovornog poslovanja, te inspirišemo kolektiv da preduzme konkretne korake ka
        ostvarivanju pozitivnih promjena, kako u okviru svoje organizacije, tako i u široj zajednici.`,
        city: "Podgorica",
        date: "09.2023",
        link: "https://www.instagram.com/p/Cxi2eD0obkk/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        showMore: false
    },
    {
        id: 9,
        title: `Osnivačica A4CSR Katya Letunovsky učestovovala "ESG Innovation Collective
        Research Conference"`,
        img: image09,
        description: `Osnivačica A4CSR Katya Letunovsky, je 13. Septembra
        2023. godine učestovovala "ESG Innovation Collective
        Research Conference" u Amsterdamu, te podijelila svoje
        uvide o mjerenju društvenog uticaja sa akademskim,
        privatnim i lokalnim partnerima iz cijele Evrope. Ova
        konferencija je imala za cilj istražiti ulogu grada u razvoju
        ESG pejzaža i kako gradovi mogu iskoristiti svoju stručnost u
        oblasti društvenog uticaja u ovom dinamičnom vremenu.
        Katya je predstavila inovativne alate i podatke kompanije
        “Habidatum”, sa sjedištem u Njujorku, čiji je suosnivač,
        naglašavajući pri tom važnost društveno odgovornog poslovanja - što je temeljna misija
        našeg pokreta A4CSR. Njeno predavanje je bilo inspirativno i informativno, pružajući dublji
        uvid u napredne pristupe mjerenju i analizi društvenog uticaja. Ova konferencija predstavlja
        važan korak ka izgradnji održivijeg i inkluzivnijeg društva, a mi smo ponosni što je naša
        članica Katya bila ključni dio tog procesa.`,
        city: "Podgorica",
        date: "11.2023",
        link: "https://www.instagram.com/p/CzOUkCNoIt7/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        showMore: false
    },
    {
        id: 11,
        title: `Članica NVO A4CSR, Antonina Berkviska prisustvovala obuci o otvorenim
        podacima i partnerstvu za otvorenu javnu upravu`,
        img: image11,
        description: `Članica NVO A4CSR, Antonina Berkviska, nedavno je prisustvovala obuci o otvorenim
        podacima i partnerstvu za otvorenu javnu upravu, koju je organizovala “Mreža za omladinski
        aktivizam Crne Gore” (MOACG) u saradnji sa Organizacijom KOD, uz podršku National
        Democratic Institute. Ova obuka je imala za cilj da pruži podršku predstavnicima NVO
        sektora u korišćenju otvorenih podataka u svakodnevnom radu.
        Ponosni smo što naša NVO A4CSR aktivno doprinosi
        inkluzivnijoj i transparentnijoj implementaciji crnogorskog
        Nacionalnog akcionog plana Partnerstva za otvorenu javnu
        upravu (OGP), što je ujedno i cilj projekta pomenutih
        organizacija. Ovom obukom jačamo naše kapacitete unutar
        NVO, omogućavajući nam da još bolje odgovorimo na potrebe zajednice i ostvarimo
        pozitivan uticaj na društvene izazove. Ova inicijativa odražava našu predanost
        transparentnosti, inkluziji i aktivnom učešću u izgradnji boljeg društva za sve.`,
        city: "Podgorica",
        date: "12.2023",
        link: "https://www.instagram.com/p/Czywbf2oaSG/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        showMore: false
    },
    {
        id: 10,
        title: `Osnivačica A4CSR Katya Letunovsky održala predavanje u Focuss Space-u u
        Baru o viznim režimima`,
        img: image10,
        description: `U Crnoj Gori su nedavno dočekane mnoge IT kompanije iz
        Istočne Evrope, koje aktivno doprinose izgradnji
        preduzetničkog iskustva i zajednica. Sa zadovoljstvom
        ističemo naš doprinos ovom cilju kroz aktivnosti naše NVO
        A4CSR.
        Događaj koji se istakao u ovoj inicijativi bio je sajam
        poslova u IT sektoru održan 4. Novembra 2023. godine u
        Focuss.Space u Baru. Tokom ovog sajma, prisutni su imali
        priliku da prisustvuju predavanjima i prezentacijama
        različitih kompanija. Posebno je istaknuto predavanje Katye
        Letunovsky iz A4CSR, koja je govorila o viznim režimima
        i procedurama za osobe koje žele putovati iz Crne Gore u
        inostranstvo. Tema je izazvala veliko interesovanje među prisutnima, s mnogo postavljenih
        pitanja i dijeljenja ličnih priča, što je jasno pokazalo važnost ovog pitanja, a predavanje je
        pružilo veliku korist doseljenicima.
        Ova inicijativa dobila je značajnu podršku od strane Fondacije Pristanište, koja se aktivno
        angažuje u podršci doseljenicima i integraciji zajednice. Nastavljamo sa ponosom podržavati
        takve napore koji doprinose razvoju inkluzivnog i prosperitetnog društva.`,
        city: "Bar",
        date: "11.2023",
        link: "https://www.instagram.com/p/Czv-eEsoynL/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        showMore: false
    },
    {
        id: 8,
        title: `Osnivačica A4CSR Katya Letunovsky gostovala u “The Oblique Life” podcast-u
        ”Global Goals” o inkluziji pravednosti`,
        img: image08,
        description: `17. Oktobra 2023. godine osnivačica A4CSR Katya Letunovsky gostovala u 14. epizodi
        “The Oblique Life” podcast-a ”Global Goals” o inkluziji pravednosti ključnih tema održivog
        razvoja definisane kroz 17 ciljeva održivog razvoja
        Ujedinjenih nacija (SDG). Kroz realne priče i izazove s
        terena, ova epizoda istražuje kako tehnologija, ljudi i
        resursi mogu doprinijeti ostvarenju ciljeva održivog
        razvoja do 2030. godine. Dostupna na platformama poput
        Spotify-a i Apple Music-a, ova epizoda pruža inspiraciju i
        uvide u inovativne pristupe koji vode ka stvaranju
        prosperitetnog, mirnog i održivog svijeta.
        Fokusirajući se na inkluzivni razvoj gradova i regiona,
        Katya Letunovsky, pružila je vrijedne uvide o tome kako
        veliki podaci mogu biti korisni alat za postizanje
        inkluzivnosti i pravednosti u urbanom planiranju i održivom razvoju.`,
        city: "Podgorica",
        date: "10.2023",
        link: "https://www.instagram.com/p/Cygi16mo04R/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        showMore: false
    },
    {
        id: 3,
        title: `Edukativna radionica u saradnji sa Glavnim gradom Podgorica “Mitovi o
        društveno odgovornom poslovanju”`,
        img: image03,
        description: `U saradnji sa Glavnim gradom Podgorica NVO “Asocijacija za društveno odgovorno
        poslovanje” je 14. septembra 2023. godine, u prostorijama Birziminium Hub-a organiziovala
        besplatnu edukativnu radionicu pod nazivom “Mitovi o društveno odgovornom poslovanju”.
        Predavač je bila mr Vanja Janković, osnivačiva NVO A4CSR.
        Društvena odgovornost preduzeća (DOP eng. CSR) je koncept upravljanja koji opisuje kako
        kompanija doprinosi dobrobiti zajednice i društva kroz mjere zaštite životne sredine i društva.
        DOP igra ključnu ulogu u tome kako brendove percipiraju kupci i njihova ciljna publika.
        Takođe može pomoći privlačenju zaposlenih i investitora koji daju prioritet ciljevima
        društveno odgovornog poslovanja koje je kompanija identifikovala. Međutim DOP je takođe
        često pogrešno shvaćen ili zloupotrebljen od strane nekih preduzeća i kritičara. Tokom
        edukativne radionice prisutni su imali priliku da istraže neke od uobičajenih zabluda i mitova
        o društveno odgovornom poslovanju.`,
        city: "Podgorica",
        date: "09.2023",
        link: "https://www.instagram.com/p/CxNByF8Il4W/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        showMore: false
    },
    {
        id: 6,
        title: `Međunarodni dan čišćenja obala - akcija sa NVO “Zero Waste Montenegro”`,
        img: image06,
        description: `Uvažavajući značaj očuvanja čistoće naših obala i vodenih resursa, NVO "Asocijacija za
        društveno odgovorno poslovanje" sa ponosom se pridružila najvećem svjetskom
        volonterskom poduhvatu - Međunarodnom danu čišćenja obala. Ova inspirativna akcija, koju
        je organizovala Zero Waste Montenegro 16.
        Septembra. 2023. godine, okupila je brojne
        zainteresovane strane kako bi se suočile s globalnim
        izazovom otpada u morima i vodotocima.
        Naša NVO je s entuzijazmom doprinijela čišćenju na
        lokaciji plaže "Galeb", svjesna da otpad u blizini
        vodenih tokova može izazvati različite probleme. Ne
        samo što utiče na kvalitet vode, već ugrožava i biljni i
        životinjski svijet, te zagađuje okolinu koja je ključna za
        turizam i rekreaciju, od kojeg zavisi i crnogorska
        ekonomija.
        Ovaj čin solidarnosti i brige za našu planetu predstavlja korak ka boljem sutra, pokazujući da
        zajedničkim naporima možemo stvoriti pozitivne promjene u našem okruženju. NVO A4CSR
        ostaje posvećena podršci održivim praksama i promovisanju svijesti o zaštiti životne sredine,
        te poziva sve da se pridruže u borbi za čistiju i zdraviju planetu za buduće generacije.`,
        city: "Podgorica",
        date: `09.2023`,
        link: "https://www.instagram.com/p/CxQIMR5oD-T/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        showMore: false
    },
    {
        id: 7,
        title: `Potpisivanje Memoranduma o saradnji sa Fondacijom "Cini Dobro"`,
        img: image07,
        description: `Postizanje dugoročnih partnerskih odnosa s organizacijama koje dijele naše vrijednosti
        ključno je za ostvarenje naše misije. 28. Septembra 2023. godine smo napravili značajan
        korak u tom smjeru - potpisivanjem Memoranduma o saradnji sa Fondacijom "Čini Dobro".
        Ova saradnja predstavlja spoj snaga i resursa, s ciljem unapređenja društveno odgovornog
        poslovanja, održivog razvoja i promocije pozitivnih primjera iz prakse.
        Kroz ovu saradnju, Fondacija "Čini dobro" i
        NVO "Asocijacija za društveno odgovorno
        poslovanje" će udružiti svoje napore u
        organizovanju različitih aktivnosti s ciljem
        osvješćivanja javnosti i edukacije. Naš
        zajednički fokus bit će na sticanju vještina i
        znanja iz oblasti društveno odgovornog poslovanja, održivog razvoja i zaštite životne sredine.
        Ovaj Memorandum predstavlja početak plodne saradnje koja će omogućiti da zajedno
        ostvarimo pozitivan uticaj na društvo i okolinu. Sa entuzijazmom i posvećenošću, radujemo
        se budućim projektima i inicijativama koje će proizaći iz ove saradnje.`,
        city: "Podgorica",
        date: "09.2023",
        link: "https://www.instagram.com/p/Cxu03xVI0fI/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        showMore: false
    }
]

export default projektiList;